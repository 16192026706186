import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { TwtOrderType, TwtPerformableConfig } from '~/tenants/twt/model/TwtOrder';

interface TwtSimpleJobConfig {
  revenueMinimum?: number;
  revenuePer1000?: number;
  expenseMinimum?: number;
  expensePer1000?: number;
  onsiteMinimum: number;
  onsitePer1000: number;
}

export abstract class TwtSimpleJob<P extends TwtPerformableConfig> extends TwtJob<P> {
  protected abstract get config(): TwtSimpleJobConfig;

  protected expensePer(_thousands: number): number | undefined {
    return undefined;
  }

  protected revenueMinimum(): number | undefined {
    return undefined;
  }

  protected revenuePer(_thousands: number): number | undefined {
    return undefined;
  }

  protected get revenueFirstHour(): Big | null {
    return null;
  }

  protected get expenseFirstHour(): Big | null {
    return null;
  }

  protected get revenuePerMinute(): Big | null {
    return null;
  }

  protected get expensePerMinute(): Big | null {
    return null;
  }

  get minutes() {
    return 0;
  }

  onsite(): number {
    return this.config.onsiteMinimum + this.sqftMulitpler() * this.config.onsitePer1000;
  }

  protected get extraMinutes() {
    return this.minutes - 60;
  }

  protected simpleExpense() {
    if (this.hourly) {
      let amount = this.expenseFirstHour ?? new Big(0);

      if (this.extraMinutes > 0 && this.expensePerMinute) {
        amount = amount.plus(this.expensePerMinute.times(this.extraMinutes));
      }

      return amount;
    }

    let expensePer = this.expensePer(this.sqftMulitpler());

    if (expensePer === undefined) {
      expensePer = this.sqftMulitpler() * (this.config.expensePer1000 ?? 0);
    }

    return new Big(this.config.expenseMinimum ?? 0).plus(expensePer);
  }

  protected simpleRevenue() {
    if (this.hourly) {
      let amount = this.revenueFirstHour ?? new Big(0);

      if (this.extraMinutes > 0 && this.revenuePerMinute) {
        amount = amount.plus(this.revenuePerMinute.times(this.extraMinutes));
      }

      return {
        amount,
        type: 'hourly' as const,
      };
    }

    let revenuePer = this.revenuePer(this.sqftMulitpler(true));

    if (revenuePer === undefined) {
      revenuePer = this.sqftMulitpler(true) * (this.config.revenuePer1000 ?? 0);
    }

    const revenueMinimum = this.revenueMinimum() ?? this.config.revenueMinimum ?? 0;

    return {
      amount: new Big(revenueMinimum).plus(revenuePer),
      type: 'sqft' as const,
    };
  }

  protected sqftMulitpler(_revenue = false) {
    if (this.sqft === 0) {
      return 0;
    }

    return Math.floor((this.sqft - 1) / 1000);
  }
}
