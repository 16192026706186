import { TwtSimpleJob } from '~/tenants/twt/performable/TwtSimpleJob';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { DisplayData, TenantJobLine, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';

export const TWT_PHOTO_MINIMUM = 20;
export const TWT_PHOTO_PER_1000 = 5;
export const TWT_PHOTO_TWILIGHT = 199;
export const TWT_PHOTO_NEIGHBORHOOD = 35;
export const TWT_RENTAL_10 = 150;
export const TWT_RENTAL_20 = 200;
export const TWT_PHOTO_EXTRA = 25;
export const TWT_PHOTO_HIGHLIGHT_RESIDENTIAL = 100;
export const TWT_PHOTO_HIGHLIGHT_COMMERCIAL = 200;
export const TWT_PHOTO_DOMAIN = 30;

export function twt_calculate_photo_images(sqft: number) {
  return Math.floor((sqft - 1) / 1000) * TWT_PHOTO_PER_1000 + TWT_PHOTO_MINIMUM;
}

export default class TwtPhotoJob extends TwtSimpleJob<typeof TwtPhotoConfig> {
  protected get config() {
    return {
      revenueMinimum: 155,
      revenuePer1000: 25,
      onsiteMinimum: 30,
      onsitePer1000: 15,
    };
  }

  get performable() {
    return TwtPhotoConfig;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get short() {
    return this.metadata.twilight ? 'NIGHT' : 'DAY';
  }

  get hourly() {
    return this.order.metadata.type === TwtOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtPhotoConfig)['schema']> {
    if ([TwtOrderType.SHORT_TERM_RENTAL, TwtOrderType.LONG_TERM_RENTAL].includes(this.order.metadata.type)) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: TwtPhotoType.INTERIOR_EXTERIOR,
        photos: 20,
      };
    }

    return {
      type: TwtPhotoType.INTERIOR_EXTERIOR,
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  info(): DisplayData[] {
    const data = super.info();

    data.push({
      name: 'Time of Day',
      value: this.metadata.twilight ? 'Twilight' : 'Daylight',
      provider: true,
      schedule: true,
    });

    if (this.metadata.instructions) {
      data.push({
        name: 'Instructions',
        value: this.metadata.instructions,
        provider: true,
        schedule: true,
      });
    }

    if (this.metadata.neighborhood && this.metadata.neighborhood.length > 0) {
      data.push({
        name: 'Neighborhoods',
        value: this.metadata.neighborhood,
      });
    }

    if (this.metadata.neighborhood_custom && this.metadata.neighborhood_custom.length > 0) {
      data.push({
        name: 'Custom Neighborhoods',
        value: this.metadata.neighborhood_custom.map((c) => c.location),
        provider: true,
      });
    }

    if (this.metadata.highlights) {
      data.push({
        name: 'Property Highlights',
        value: 'Yes',
        schedule: true,
        provider: true,
      });
    }

    data.push({
      name: 'Type',
      value: this.calculate().name,
      schedule: true,
      provider: true,
    });

    if (this.photos > 0) {
      data.push({
        name: 'Photos',
        value: this.photos.toString(),
        schedule: true,
        provider: true,
      });
    }

    return data;
  }

  onsite(): number {
    let onsite = 0;

    switch (this.metadata.type) {
      case TwtPhotoType.INTERIOR_EXTERIOR:
        onsite = super.onsite();

        if (this.provider) {
          if (this.sqft <= 1000) {
            onsite = this.provider.config.photo_onsite_0_1000 ?? onsite;
          } else if (this.sqft <= 2000) {
            onsite = this.provider.config.photo_onsite_1001_2000 ?? onsite;
          } else if (this.sqft <= 3000) {
            onsite = this.provider.config.photo_onsite_2001_3000 ?? onsite;
          } else if (this.sqft <= 4000) {
            onsite = this.provider.config.photo_onsite_3001_4000 ?? onsite;
          } else if (this.sqft <= 5000) {
            onsite = this.provider.config.photo_onsite_4001_5000 ?? onsite;
          } else if (this.sqft <= 6000) {
            onsite = this.provider.config.photo_onsite_5001_6000 ?? onsite;
          } else {
            const base = this.provider.config.photo_onsite_0_1000 ?? this.config.onsiteMinimum;
            const per1000 = this.provider.config.photo_onsite_every_1000_after_6000 ?? this.config.onsitePer1000;

            onsite = base + per1000 * Math.ceil((this.sqft - 6000) / 1000);
          }
        }

        break;
      case TwtPhotoType.RESHOOT:
        onsite = 30;
        break;
      case TwtPhotoType.EXTERIOR_ONLY:
        onsite = 15;
        break;
    }

    // if (this.metadata.neighborhood) {
    //   onsite += 15;
    // }

    if (this.metadata.highlights) {
      onsite += this.provider?.config.highlights_onsite ?? 30;
    }

    return onsite;
  }

  get configurable() {
    return true;
  }

  get submission() {
    return true;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
  }

  get minutes(): number {
    return this.metadata.minutes ?? 0;
  }

  protected get revenueFirstHour() {
    if (this.order.metadata.type !== TwtOrderType.COMMERCIAL) {
      return null;
    }

    const first = new Big(this.order.buyer.first_hour_photo_revenue ?? 0);

    if (first.gt(0)) {
      return first;
    }

    return new Big('350');
  }

  protected get expenseFirstHour() {
    if (this.order.metadata.type !== TwtOrderType.COMMERCIAL) {
      return null;
    }

    const first = new Big(this.order.buyer.first_hour_photo_expense ?? 0);

    if (first.gt(0)) {
      return first;
    }

    return new Big('227.50');
  }

  protected get revenuePerMinute() {
    if (this.order.metadata.type !== TwtOrderType.COMMERCIAL) {
      return null;
    }

    return new Big('5.00');
  }

  protected get expensePerMinute() {
    if (this.order.metadata.type !== TwtOrderType.COMMERCIAL) {
      return null;
    }

    return new Big('3.25');
  }

  expenseLines(): TenantJobLine[] {
    let lines: TenantOrderLine[] = [];

    if (this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR && this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Shoot',
        amount: new Big(TWT_PHOTO_TWILIGHT),
      });
    }

    const calculated = this.calculate();

    if (calculated.expense.gt(0)) {
      lines.push({
        id: 'photos',
        description: `${calculated.name} (${this.photos} Photos)`,
        amount: calculated.expense,
      });
    }

    if (this.metadata.neighborhood_custom && this.metadata.neighborhood_custom.length > 0) {
      lines.push({
        id: 'neighborhood',
        description: 'Neighborhood Amenities',
        amount: new Big(25).times(this.metadata.neighborhood_custom.length),
      });
    }

    // if (this.order.metadata.type === TwtOrderType.SHORT_TERM_RENTAL) {
    //   lines.push({
    //     id: 'highlights',
    //     description: 'Highlights',
    //     amount: highlights,
    //   });
    // }

    return lines;
  }

  revenueLines(): TenantJobLine[] {
    let lines: TenantOrderLine[] = [];

    if (this.metadata.domain) {
      lines.push({
        amount: new Big(TWT_PHOTO_DOMAIN),
        description: `Custom Domain (${this.metadata.domain})`,
        id: 'domain',
        taxable: true,
        phase: 'submitted',
      });
    }

    if (this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR) {
      if (this.metadata.twilight) {
        lines.push({
          id: 'twilight',
          description: 'Twilight Shoot',
          phase: 'delivered',
          taxable: true,
          amount: new Big(TWT_PHOTO_TWILIGHT),
        });
      }

      if (this.extraPhotos > 0) {
        lines.push({
          id: 'extra_photos',
          description: `Extra Photos (${this.extraPhotos})`,
          phase: 'delivered',
          taxable: true,
          amount: new Big(TWT_PHOTO_EXTRA).times(this.extraPhotosMultiplier),
        });
      }
    }

    if (this.metadata.neighborhood_custom && this.metadata.neighborhood_custom.length > 0) {
      lines.push({
        id: 'neighborhood_custom',
        description: `Custom Neighborhood Amenities (${this.metadata.neighborhood_custom.length} Areas)`,
        phase: 'delivered',
        taxable: true,
        discountable: true,
        amount: new Big(TWT_PHOTO_NEIGHBORHOOD).times(this.metadata.neighborhood_custom.length),
      });
    }

    if (this.metadata.neighborhood && this.metadata.neighborhood.length > 0) {
      const count = this.metadata.neighborhood.length === 1 ? '1 Area' : `${this.metadata.neighborhood.length} Areas`;

      lines.push({
        id: 'neighborhood',
        description: `Stock Neighborhood Amenities (${count})`,
        phase: 'delivered',
        taxable: true,
        discountable: true,
        amount: new Big(TWT_PHOTO_NEIGHBORHOOD).times(this.metadata.neighborhood.length),
      });
    }

    if (this.metadata.highlights) {
      lines.push({
        id: 'highlights',
        description: 'Property Highlights',
        phase: 'delivered',
        discountable: true,
        taxable: true,
        amount: new Big(
          this.order.metadata.type === TwtOrderType.COMMERCIAL
            ? TWT_PHOTO_HIGHLIGHT_COMMERCIAL
            : TWT_PHOTO_HIGHLIGHT_RESIDENTIAL,
        ),
      });
    }

    const calculated = this.calculate();

    if (calculated.revenue.gt(0)) {
      let photos = this.photos;

      if (this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR) {
        // TODO: smell
        photos -= this.extraPhotos;
      }

      let description = `${calculated.name} (${photos} Photos)`;

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        description = `Commercial ${calculated.name}`;
      }

      if (this.minutes > 0) {
        description += ` (${this.minutes} Minutes)`;
      }

      lines.push({
        id: 'photos',
        description,
        phase: 'delivered',
        discountable: true,
        taxable: true,
        amount: calculated.revenue,
      });
    }

    // if (highlights) {
    //   lines.push({
    //     id: 'highlights',
    //     description: 'Vignettes',
    //     amount: highlights,
    //   });
    // }

    return lines;
  }

  protected sqftMulitpler(revenue: boolean = false) {
    const thousands = super.sqftMulitpler(revenue);

    return (revenue ? 0 : this.extraPhotosMultiplier) + thousands;
  }

  protected expensePer(thousands: number) {
    let expense = 80;

    // if (this.provider?.elevated) {
    //   expense += 10;
    // }

    if (thousands >= 10) {
      // $15/per 1000 sqft after 10,000+ sqft
      expense += 15 * (thousands - 9);
    }

    if (thousands >= 5) {
      // $20/per 1000 sqft after 4,000+ sqft
      expense += 20 * Math.min(5, thousands - 4);
    }

    if (thousands >= 4) {
      expense += 10;
    }

    // $5/per 1000 sqft under 4,000 sqft
    expense += 5 * Math.min(3, thousands);

    return expense;
  }

  protected get extraPhotos() {
    if (this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR) {
      return this.metadata.photos ?? 0;
    }

    if (this.photos === 0) {
      return 0;
    }

    return this.photos - 5;
  }

  protected get extraPhotosMultiplier() {
    return Math.ceil(this.extraPhotos / 5);
  }

  protected get photos() {
    if (this.order.metadata.subtype == TwtPropertyType.LAND) {
      return 20;
    }

    if ([TwtOrderType.LONG_TERM_RENTAL, TwtOrderType.SHORT_TERM_RENTAL].includes(this.order.metadata.type)) {
      return this.metadata.photos ?? 20;
    }

    const photos = this.metadata.photos ?? 0;

    if (this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR) {
      return twt_calculate_photo_images(this.sqft) + photos;
    }

    return photos;
  }

  protected calculate() {
    let name: string = 'Daytime Shoot';
    let revenue: Big = new Big(0);
    let expense: Big = new Big(0);

    if (this.metadata.type === TwtPhotoType.RESHOOT) {
      name = 'Reshoot';
      revenue = new Big(85).plus(new Big(25).times(this.extraPhotosMultiplier));
      expense = new Big(65).plus(new Big(10).times(this.extraPhotosMultiplier));
    } else if (this.metadata.type === TwtPhotoType.EXTERIOR_ONLY) {
      const land = this.order.metadata.subtype === TwtPropertyType.LAND;

      if (this.metadata.twilight) {
        name = land ? 'Twilight Land Shoot' : 'Twilight Exterior Only';
        revenue = this.order.metadata.type === TwtOrderType.COMMERCIAL ? new Big(350) : new Big(249);
        expense = this.order.metadata.type === TwtOrderType.COMMERCIAL ? new Big('227.50') : new Big(190);
      } else if (this.order.metadata.subtype === TwtPropertyType.LAND) {
        revenue = new Big(155);
        expense = new Big(80);
        name = 'Land Shoot';
      } else {
        name = 'Exterior Only';
        revenue = new Big(75);
        expense = new Big(55);
      }

      revenue = revenue.plus(new Big(25).times(this.extraPhotosMultiplier));
      expense = expense.plus(new Big(10).times(this.extraPhotosMultiplier));
    } else if (this.order.metadata.type === TwtOrderType.SHORT_TERM_RENTAL) {
      revenue = new Big(315);
      expense = new Big(315);

      if (this.photos >= 40) {
        revenue = revenue.plus(TWT_RENTAL_20);
        expense = new Big(615);
      } else if (this.photos >= 30) {
        revenue = revenue.plus(TWT_RENTAL_10);
        expense = new Big(465);
      }
    } else if (this.order.metadata.type === TwtOrderType.LONG_TERM_RENTAL) {
      revenue = new Big(215);
      expense = new Big(215);

      if (this.photos >= 40) {
        revenue = revenue.plus(TWT_RENTAL_20);
        expense = new Big(515);
      } else if (this.photos >= 30) {
        revenue = revenue.plus(TWT_RENTAL_10);
        expense = new Big(365);
      }
    } else {
      expense = this.simpleExpense();

      if (this.provider?.config.senior) {
        expense = expense.plus(10);
      }

      const calculatedRevenue = this.simpleRevenue();

      if (calculatedRevenue.amount) {
        revenue = calculatedRevenue.amount;
      }
    }

    return {
      name,
      revenue,
      expense,
    };
  }
}
