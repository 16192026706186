import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { PersonaType } from '~/tenants/common/TenantPersona';
import TwtStagingConfig from '~/tenants/twt/performable/staging/TwtStagingConfig';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { TenantJobInput } from '~/tenants/common/TenantJob';

export class TwtStagingJob extends TwtJob<typeof TwtStagingConfig> {
  get performable() {
    return TwtStagingConfig;
  }

  get eligible(): boolean {
    return (
      this.order.metadata.type === TwtOrderType.FOR_SALE_LISTING && this.order.metadata.subtype !== TwtPropertyType.LAND
    );
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtStagingConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtStagingConfig.schema),
      photos: 1,
    } as any;
  }

  get input(): TenantJobInput | null {
    return {
      performables: [TwtPhotoConfig.id],
      required: this.metadata.photos,
      title: 'Select Staging Photos',
      description: 'choose which photos you would like virtually staged',
    };
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get short() {
    return 'STAGE';
  }

  get media() {
    return { persona: PersonaType.OFFICE, microsite: MicrositeFileType.GALLERY };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    const photos = this.metadata.photos; //this.metadata.locations.reduce((acc, loc) => acc + loc.photos, 0);

    lines.push({
      id: 'staging',
      description: `Virtual Staging (${photos} Photos)`,
      amount: new Big(this.order.metadata.type === TwtOrderType.COMMERCIAL ? 55 : 45).times(photos),
      discountable: true,
      taxable: true,
    });

    return lines;
  }
}
