import Twt3dConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import { TwtSimpleJob } from '~/tenants/twt/performable/TwtSimpleJob';
import { DisplayData, TenantJobLine, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { DeliverableType } from '~common/model/Deliverable';

export default class TwtMatterportJob extends TwtSimpleJob<typeof Twt3dConfig> {
  protected get config() {
    return {
      onsiteMinimum: 30,
      onsitePer1000: 10,
      expenseMinimum: 50,
      expensePer1000: 30,
    };
  }

  get performable() {
    return Twt3dConfig;
  }

  get deliverable() {
    return DeliverableType.MATTERPORT;
  }

  get short() {
    return '3D';
  }

  defaultValue(): ZodVersionedMetadata<(typeof Twt3dConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      floors: 1,
    };
  }

  get review() {
    return Boolean(this.order.buyer.matterport_transfer);
  }

  onsite(): number {
    let onsite = super.onsite();

    if (this.provider) {
      if (this.sqft <= 1000) {
        onsite = this.provider.config.scan_onsite_0_1000 ?? onsite;
      } else if (this.sqft <= 2000) {
        onsite = this.provider.config.scan_onsite_1001_2000 ?? onsite;
      } else if (this.sqft <= 3000) {
        onsite = this.provider.config.scan_onsite_2001_3000 ?? onsite;
      } else if (this.sqft <= 4000) {
        onsite = this.provider.config.scan_onsite_3001_4000 ?? onsite;
      } else if (this.sqft <= 5000) {
        onsite = this.provider.config.scan_onsite_4001_5000 ?? onsite;
      } else if (this.sqft <= 6000) {
        onsite = this.provider.config.scan_onsite_5001_6000 ?? onsite;
      } else {
        const base = this.provider.config.scan_onsite_0_1000 ?? this.config.onsiteMinimum;
        const per1000 = this.provider.config.scan_onsite_every_1000_after_6000 ?? this.config.onsitePer1000;

        onsite = base + per1000 * Math.ceil((this.sqft - 6000) / 1000);
      }
    }

    return onsite;
  }

  info(): DisplayData[] {
    const info = super.info();

    info.push({
      name: 'Floors',
      value: this.metadata.floors.toString(),
      provider: true,
      schedule: true,
    });

    if (this.metadata.detached && this.metadata.detached.length > 0) {
      info.push({
        name: 'Detached Structures',
        value: this.metadata.detached.join(','),
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    if (this.metadata.branded_url) {
      info.push({
        name: 'Branded URL',
        value: this.metadata.branded_url,
        provider: true,
      });
    }

    if (this.metadata.unbranded_url) {
      info.push({
        name: 'Unbranded URL',
        value: this.metadata.unbranded_url,
        provider: true,
      });
    }

    return info;
  }

  get eligible(): boolean {
    return this.order.metadata.subtype !== TwtPropertyType.LAND;
  }

  get submission() {
    return false;
  }

  get configurable() {
    return true;
  }

  protected revenueMinimum() {
    return this.orderHasPhotos ? 120 : 125;
  }

  protected revenuePer(thousands: number) {
    return (this.orderHasPhotos ? 50 : 75) * thousands;
  }

  expenseLines(): TenantJobLine[] {
    let amount = this.simpleExpense();

    if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
      amount = new Big('0.05').times(this.sqft);
    }

    return [
      {
        amount,
        description: '3D Scan',
        id: '3d',
      },
    ];
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    let amount = this.simpleRevenue().amount ?? new Big(0);

    if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
      amount = new Big('0.10').times(this.sqft);
    }

    lines.push({
      amount,
      description: '3D Scan',
      id: '3d',
      phase: 'delivered',
      discountable: true,
      taxable: true,
    });

    if (this.order.buyer.matterport_discount_dollars) {
      lines.push({
        amount: new Big(this.order.buyer.matterport_discount_dollars).times(-1),
        description: 'Matterport Discount',
        id: 'matterport_discount_dollars',
        taxable: true,
        discount: true,
      });
    }

    return lines;
  }
}
