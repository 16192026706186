import { createPerformableConfig, TenantId } from '../../../common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '../../../../lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'matterport-video',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Matterport Video',
    thumbnail: 'tenant/twt/matterport/4.png',
    color: '#593370',
    short:
      'A HD video using the Matterport walkthrough as the base, enhanced with agent branding, floor plan overlays and video effects.',
    long: 'We create an HD (16:9 aspect ratio) video using the Matterport walkthrough as the base, but we enhance it with agent branding, floor plan overlays and video effects to give prospective buyers a complete virtual walkthrough experience, all within the video. Branded/unbranded versions added to your tour, available for download. We also create a "Social Reel" version (in a vertical 9:16 aspect ratio) that is up to 90 seconds long, perfect for uploading to social media platforms like Instagram, TikTok and Facebook',
    tenant: TenantId.TWIST_TOURS,
    images: [],
  },
);
