import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import FormGroup from '~/components/form/FormGroup';
import ResponsiveImage from '~/components/ResponsiveImage';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import classNames from 'classnames';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export const TWT_VIRTUAL_SKYS = [
  { name: 'Twilight', url: 'tenant/twt/twilight/virtual/1.tif' },
  { name: 'Dusk', url: 'tenant/twt/twilight/virtual/2.tif' },
  { name: 'Golden Clouds', url: 'tenant/twt/twilight/virtual/3.tif' },
  { name: 'Sunset', url: 'tenant/twt/twilight/virtual/4.tif' },
  { name: 'Pink Sunset', url: 'tenant/twt/twilight/virtual/5.tif' },
  { name: 'Dusk 2', url: 'tenant/twt/twilight/virtual/10.tif' },
];

export default function TwtVirtualForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  const [_sky, sky, setSky] = useZodFormFieldSingle('sky');

  return (
    <FormGroup>
      <div className="space-y-2">
        <div className="text-sm font-medium text-center uppercase">Choose a sky:</div>
        <div className="grid grid-cols-3 gap-2">
          {TWT_VIRTUAL_SKYS.map(({ name, url }, index) => (
            <div key={url} className="flex w-full flex-col space-y-1">
              <div className="text-xs text-center font-medium">{name}</div>
              <ResponsiveImage
                path={url}
                onClick={() => (sky === url ? setSky(undefined) : setSky(url))}
                className={classNames('cursor-pointer hover:scale-105 transition rounded-theme w-full', {
                  'ring-4 ring-green-300': sky === url,
                  'opacity-50 hover:opacity-100': sky && sky !== url,
                })}
                width={150}
                height={100}
              />
            </div>
          ))}
        </div>
      </div>
      <ZodFieldHidden name="sky" value={sky} />
      <FormHorizontal label="# of Photos">
        <ZodFieldInput type="number" name="photos" />
      </FormHorizontal>
    </FormGroup>
  );
}
