import { registerTenant, TenantId } from '~/tenants/common/registry';
import { TimeZone } from '~/lib/enum';
import TwtMatterportTourConfig from '~/tenants/twt/package/forsale/matterport/TwtMatterportTourConfig';
import TwtMatterportTourForm from '~/tenants/twt/package/forsale/matterport/TwtMatterportTourForm';
import TwtFloorplanTourForm from '~/tenants/twt/package/forsale/floorplan/TwtFloorplanTourForm';
import { TwtOrder, TwtOrderSchema } from '~/tenants/twt/model/TwtOrder';
import TwtFloorplanTourConfig from '~/tenants/twt/package/forsale/floorplan/TwtFloorplanTourConfig';
import TwtOrderForm from '~/tenants/twt/forms/TwtOrderForm';
import TwtOrderWizardType from '~/tenants/twt/forms/TwtOrderWizardType';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import TwtMatterportJob from '~/tenants/twt/performable/matterport/TwtMatterportJob';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtPhotoJob from '~/tenants/twt/performable/photo/TwtPhotoJob';
import TwtFloorplanJob from '~/tenants/twt/performable/floorplan/TwtFloorplanJob';
import TwtCustomer, { TwtCustomerSchema } from '~/tenants/twt/model/TwtCustomer';
import TwtAerialConfig from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import { TwtAerialJob } from '~/tenants/twt/performable/aerial/TwtAerialJob';
import TwtAerialForm from '~/tenants/twt/performable/aerial/TwtAerialForm';
import TwtPhotoForm from '~/tenants/twt/performable/photo/TwtPhotoForm';
import TwtFloorplanForm from '~/tenants/twt/performable/floorplan/TwtFloorplanForm';
import TwtProviderForm from '~/tenants/twt/forms/TwtProviderForm';
import TwtCustomerForm from '~/tenants/twt/forms/TwtCustomerForm';
import { TWT_PROVIDER_DATA } from '~/tenants/twt/data';
import TwtProvider from '~/tenants/twt/model/TwtProvider';
import TwtRentalFloorplanConfig from '~/tenants/twt/package/rental/floorplan/TwtRentalFloorplanConfig';
import TwtRentalFloorplanForm from '~/tenants/twt/package/rental/floorplan/TwtRentalFloorplanForm';
import TwtRentalAerialConfig from '~/tenants/twt/package/rental/aerial/TwtRentalAerialConfig';
import TwtRentalAerialForm from '~/tenants/twt/package/rental/aerial/TwtRentalAerialForm';
import { Holiday } from '~common/holidays/holidays';
import TwtMatterportForm from '~/tenants/twt/performable/matterport/TwtMatterportForm';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import TwtVideoForm from '~/tenants/twt/performable/video/TwtVideoForm';
import { TwtVideoJob } from '~/tenants/twt/performable/video/TwtVideoJob';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';
import TwtReelForm from '~/tenants/twt/performable/reel/TwtReelForm';
import { TwtReelJob } from '~/tenants/twt/performable/reel/TwtReelJob';
import TwtEditingConfig from '~/tenants/twt/performable/editing/TwtEditingConfig';
import TwtEditingForm from '~/tenants/twt/performable/editing/TwtEditingForm';
import { TwtEditingJob } from '~/tenants/twt/performable/editing/TwtEditingJob';
import TwtVirtualConfig from '~/tenants/twt/performable/virtual/TwtVirtualConfig';
import TwtStagingConfig from '~/tenants/twt/performable/staging/TwtStagingConfig';
import TwtVirtualForm from '~/tenants/twt/performable/virtual/TwtVirtualForm';
import TwtStagingForm from '~/tenants/twt/performable/staging/TwtStagingForm';
import { TwtStagingJob } from '~/tenants/twt/performable/staging/TwtStagingJob';
import { TwtVirtualJob } from '~/tenants/twt/performable/virtual/TwtVirtualJob';
import TwtZillowConfig from '~/tenants/twt/performable/zillow/TwtZillowConfig';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import TwtZillowJob from '~/tenants/twt/performable/zillow/TwtZillowJob';
import TwtOrderTimes from '~/tenants/twt/forms/TwtOrderTimes';
import TwtOnboardingForm from '~/tenants/twt/forms/TwtOnboardingForm';
import TwtTwilightConfig from '~/tenants/twt/performable/twilight/TwtTwilightConfig';
import { TwtTwilightJob } from '~/tenants/twt/performable/twilight/TwtTwilightJob';
import TwtOrderPayment from '~/tenants/twt/forms/TwtOrderPayment';
import TwtSlideshowConfig from '~/tenants/twt/performable/slideshow/TwtSlideshowConfig';
import { TwtSlideshowJob } from '~/tenants/twt/performable/slideshow/TwtSlideshowJob';
import { TwtTwilightForm } from '~/tenants/twt/performable/twilight/TwtTwilightForm';
import TwtMatterportVideoConfig from '~/tenants/twt/performable/matterport-video/TwtMatterportVideoConfig';
import { TwtMatterportVideoJob } from '~/tenants/twt/performable/matterport-video/TwtMatterportVideoJob';

const holiday =
  'Thank you for reaching out! We are currently closed in observance of the holiday but we will get back to you as soon as we can!';

export default function registerTwt() {
  registerTenant(TenantId.TWIST_TOURS, TwtOrderSchema, {
    name: 'Twist Tours',
    domain: 'order.twisttours.com',
    background: '#f9590f',
    phone: '+18666480022',
    tax: '0.0825',
    smsPrefix: 'Twist Tours, ',
    rainforestEnable: true,
    rainforestMerchantId: 'mid_2lOERJFs1f03OkQLaVT7cn6kj8q',
    rainforestMerchantApplicationId: 'app_2lOERHnj4mSI9srhmchUCyAOFM5',
    logoRaster: 'https://media.photology.co/tenant/twt/logo.png',
    logoVector: 'https://media.photology.co/tenant/twt/logo.svg',
    legal: 'TBD',
    email: 'office@twisttours.com',
    emailEnable: true,
    emailOrderCompleteDisabled: true,
    inboxEnabled: true,
    nylasProviderInvite: true,
    providers: TWT_PROVIDER_DATA,
    providerClass: TwtProvider,
    wizardDay: true,
    wizardProvider: true,
    wizardServicesTitle: 'Photos Only & A La Carte Services',
    holidays: [Holiday.CHRISTMAS, Holiday.THANKSGIVING, Holiday.NEW_YEARS, Holiday.DAY_AFTER_THANKSGIVING],
    scheduleStartTime: '08:00',
    scheduleEndTime: '20:00',
    scheduleJobColor: true,
    scheduleHideCustomer: true,
    autorespondStart: '17:00',
    autorespondEnd: '08:30',
    autorespondWeekend: true,
    autorespondKeywords: {
      prepare:
        'To see a guide of how to prepare for your shoot, please visit: https://www.twisttours.com/how-to-prep-for-a-photo-shoot/',
    },
    autorespondHolidays: {
      [Holiday.NEW_YEARS_EVE]: holiday,
      [Holiday.NEW_YEARS]: holiday,
      [Holiday.THANKSGIVING]: holiday,
      [Holiday.JULY_4TH]: holiday,
      [Holiday.MEMORIAL_DAY]: holiday,
      [Holiday.CHRISTMAS_EVE]: holiday,
      [Holiday.CHRISTMAS]: holiday,
    },
    autorespondMessage:
      'Hello! Twist Tours is closed - please note we will have a delayed response outside of business hours. We are open M-F, 8:30am - 5pm.',
    locations: [
      {
        slug: 'atx',
        name: 'Austin',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '5618 Steven Creek Way',
          city: 'Austin',
          state: 'TX',
          zip: '78721',
          long: -97.6867845,
          lat: 30.2591101,
        },
      },
      {
        slug: 'san',
        name: 'San Antonio',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: 'Downtown',
          city: 'San Antonio',
          state: 'TX',
          zip: '78205',
          long: -98.4891503389352,
          lat: 29.428041061444006,
        },
      },
    ],
    // TODO: make typescript happy
    orderClass: TwtOrder,
    customerClass: TwtCustomer,
    nylasGrantId: '9b49b779-6c2d-402a-883a-5dce1c0c25dd',
    //nylasProviderInvite: true,
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    userback: true,
    packages: [
      {
        config: TwtMatterportTourConfig,
        form: TwtMatterportTourForm,
      },
      {
        config: TwtFloorplanTourConfig,
        form: TwtFloorplanTourForm,
      },
      {
        config: TwtRentalFloorplanConfig,
        form: TwtRentalFloorplanForm,
      },
      {
        config: TwtRentalAerialConfig,
        form: TwtRentalAerialForm,
      },
    ],
    customerSchema: TwtCustomerSchema,
    forms: {
      orderConfigure: TwtOrderForm,
      wizardType: TwtOrderWizardType,
      provider: TwtProviderForm,
      customer: TwtCustomerForm,
      payment: TwtOrderPayment,
      times: TwtOrderTimes,
      onboarding: TwtOnboardingForm,
    },
    performables: {
      [TwtPhotoConfig.id]: {
        config: TwtPhotoConfig,
        form: TwtPhotoForm,
        job: TwtPhotoJob,
      },
      [TwtTwilightConfig.id]: {
        config: TwtTwilightConfig,
        form: TwtTwilightForm,
        job: TwtTwilightJob,
      },
      [TwtMatterportConfig.id]: {
        config: TwtMatterportConfig,
        form: TwtMatterportForm,
        job: TwtMatterportJob,
      },
      [TwtVideoConfig.id]: {
        config: TwtVideoConfig,
        form: TwtVideoForm,
        job: TwtVideoJob,
      },
      [TwtFloorplanConfig.id]: {
        config: TwtFloorplanConfig,
        form: TwtFloorplanForm,
        job: TwtFloorplanJob,
      },
      [TwtAerialConfig.id]: {
        config: TwtAerialConfig,
        form: TwtAerialForm,
        job: TwtAerialJob,
      },
      [TwtReelConfig.id]: {
        config: TwtReelConfig,
        form: TwtReelForm,
        job: TwtReelJob,
      },
      [TwtSlideshowConfig.id]: {
        config: TwtSlideshowConfig,
        form: EmptyForm,
        job: TwtSlideshowJob,
      },
      [TwtEditingConfig.id]: {
        config: TwtEditingConfig,
        form: TwtEditingForm,
        job: TwtEditingJob,
      },
      [TwtVirtualConfig.id]: {
        config: TwtVirtualConfig,
        form: TwtVirtualForm,
        job: TwtVirtualJob,
      },
      [TwtStagingConfig.id]: {
        config: TwtStagingConfig,
        form: TwtStagingForm,
        job: TwtStagingJob,
      },
      [TwtZillowConfig.id]: {
        config: TwtZillowConfig,
        form: EmptyForm,
        job: TwtZillowJob,
      },
      [TwtMatterportVideoConfig.id]: {
        config: TwtMatterportVideoConfig,
        form: EmptyForm,
        job: TwtMatterportVideoJob,
      },
    },
  });
}
